import { ApiAuth } from '../api_utils/axios-base.js'
export const inventorySearchMixin = {
  data() {
    return {
      isSearch: false,
      waitingSearch: false
    }
  },
  methods: {
    async searchProductByName(productName, sucursalId) {
      try {
        const response = await ApiAuth.get('/api/productos-por-nombre-y-sucursal/',{
          params: {
            id_sucursal: sucursalId,
            nombre_producto: productName
          }
        })
        const responseData = await response.data
        // console.log('Resultado de busqueda de producto: ', responseData );
        return responseData
      } catch (error) {
        console.error('Error al obtener producto por nombre.', error);
      }
    },
    async searchInventoryByName(productName, sucursalId) {
      try {
        const response = await ApiAuth.get('/inventarios/api/inventarios-nombre-producto/',{
          params: {
            id_sucursal: sucursalId,
            nombre_producto: productName
          }
        })
        const responseData = await response.data
        // console.log('Resultado de busqueda de inventario: ', responseData );
        return responseData
      } catch (error) {
        console.error('Error al obtener producto por nombre.', error);
      }
    }
  }
}