<template>
  <v-container class="my-4 py-6">
    <v-row>
      <v-col>
        <h2>Precios de productos</h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-center text-md-right">
        <v-btn 
          text
          color="primary"
          @click="fileImport = !fileImport"
        >
          {{ textImport }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <v-col class="text-center text-md-right py-0">
        <v-btn 
          text
          color="primary"
          small
          :disabled="priceDocuments.length === 0"
          @click="downloadTemplate"
        >
          Descargar template
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row
      v-if="editing"
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        class="px-4 py-4"
        max-width="800"
      >
        <p class="text-primary">
          {{ textAction }} precio
        </p>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="product.nombre_producto"
                label="Producto"
                :rules="[rules.required]"
                disabled
              />
            </v-col>
            
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model.number="product.precio_distribuidor"
                label="Precio"
                type="number"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="actionButton"
          >
            {{ textAction }}
          </v-btn>
          <v-btn
            color="primary"
            @click="cancel"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-row
      v-if="fileImport"
      justify="center"
      class="px-2 mx-4 my-6 mx-sm-0"
    >
      <v-col
        cols="12"
        md="10"
        sm="12"
      >
        <v-file-input
          v-model="priceFile"
          accept=".xlsx"
          solo
          label="Subir archivo"
          @click:clear="clearInventoryVariables"
        >
          <template v-slot:append-outer>
            <v-btn
              style="margin-top: -6px"
              color="primary"
              :disabled="priceFile === null"
              @click="uploadFile"
            >
              Enviar
            </v-btn>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
    <v-row
      v-if="fileImport"
      justify="center"
      class="px-2 mx-4 my-6 mx-sm-0"
    >
      <component-loader v-if="importing" />
      <v-col
        v-else
        cols="12"
        md="10"
        sm="12"
      >
        <v-alert
          v-if="importResultsSize"
          border="right"
          colored-border
          type="success"
          elevation="2"
        >
          <p><strong>Precios actualizados exitosamente</strong></p>
          Total actualizados: {{ importsSuccess.length }}<br>
          Total en archivo: {{ importResultsSize }}
        </v-alert>
        <v-alert
          v-else-if="emptyFile"
          border="right"
          colored-border
          type="warning"
          elevation="2"
        >
          <p><strong>Archivo sin cantidades para actualizar</strong></p>
        </v-alert>
        <v-alert
          v-if="crashImport.length"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          <p><strong>{{ crashImport.join('; ') }}</strong></p>
        </v-alert>
        <v-alert
          v-else-if="importsErrors.length"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          <p><strong>Los siguientes productos no pudieron ser actualizados</strong></p>
          <v-list>
            <v-list-item 
              v-for="product in importsErrors"
              :key="product.producto"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ product.datos.sku }} {{ product.datos.nombre_producto }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ product.mensaje }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-if="!editing && !fileImport"
      justify="center"
      class="px-2 mx-4 my-6 mx-sm-0"
    >
      <v-col
        cols="12"
        md="10"
        sm="12"
      >
        <v-text-field
          solo
          label="Buscar producto"
          append-icon="mdi-magnify"
          clearable
          v-model="search"
          @keyup="searchText"
          @click:clear="clearText"
          :loading="waitingSearch"
        />
        <p v-if="search && search.length && search.length < 3">
          Escribe al menos tres caracteres
        </p>
      </v-col>
    </v-row>
    <v-row
      v-if="!editing && !fileImport"
      class="my-4"
      justify="center"
    >
      <v-col>
        <v-row
          v-if="!distributorBasicComplete"
          justify="center"
        >
          <h2>Es necesario dar de alta por lo menos una sucursal y un almacén</h2>
        </v-row>
        <div v-else-if="waitingSearch">
          <component-loader />
        </div>
        <div v-else-if="filteredProducts.length">
          <paginate
            name="products"
            :list="filteredProducts"
            :per="perPage"
            class="pl-0"
          >
            <v-row justify="center">
              <v-col
                v-for="product in paginated('products')" 
                :key="product.id"
                md="4"
              >
                <v-card
                  max-width="300"
                  class="mx-4 my-4"
                >
                  <v-card-title class="primary--text">
                    SKU {{ product.sku }}
                  </v-card-title>
                  <v-card-text>
                    <p class="display-1 text--primary">
                      {{ product.nombre_producto }}
                    </p>
                    <div v-if="product.precios.length">
                      {{ getPrice(product).id ? `Precio: $ ${getPrice(product).precio_distribuidor}` : 'Sin precio' }}
                    </div>
                    <div v-else>
                      Sin precio
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="primary"
                      @click="edit(product)" 
                    >
                      {{ getPrice(product).id ? "Actualizar" : "Crear"}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="deletePrice(product)"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </paginate>
          <paginate-links
            for="products"
            :async="true"
            :limit="5"
            :show-step-links="true"
            :hide-single-page="true"
            :classes="{
              'ul': 'pagination',
              'li': 'page-item',
              'li > a': 'page-link'
            }"
            @change="onPageChange"
          >
          </paginate-links>
        </div>
        <v-row 
          v-else
          justify="center"
        >
          <h2>No hay productos que mostrar</h2>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import axios from "axios"
import { validationMixin } from '@/mixins/formsValidations.js'
import { inventorySearchMixin } from '@/mixins/inventorySearch.js'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  metaInfo: {
    title: 'Precios de productos'
  },
  components: {
    componentLoader: () => import('@/components/ComponentLoader')
  },
  mixins: [validationMixin, inventorySearchMixin],
  data() {
    return {
      editing: false,
      toUpdate: false,
      importing: false,
      fileImport: false,
      priceDocuments: [],
      paginate: ['products'],
      perPage: 6,
      responsePage: 1,
      paginateLimit: 1,
      filteredProducts: [],
      search: "",
      allProducts: [],
      products: [],
      product: {
        nombre_producto: null,
        precio_distribuidor: null,
      },
      priceFile: null,
      importResults: [],
      crashImport: [],
      emptyFile: false
    }
  },
  computed: {
    ...mapState('usuario', ['userData']),
    ...mapState('products', ['distributorId']),
    ...mapGetters('usuario', ['distributorBasicComplete']),
    importResultsSize() {
      // console.log('importResults Size:', this.importResults.length);
      return this.importResults.length
    },
    importsSuccess() {
      // console.log('hubo un cambio en importResults importsSuccess');
      return this.importResults.length 
        ? this.importResults.filter(result => {
            return result.precios_actualizados 
              ? result.precios_actualizados.length > 0 
              : false
          })
        : []
    },
    importsErrors() {
      // console.log('hubo un cambio en importResults importsErrors');
      return this.importResults.length 
        ? this.importResults.filter(result => {
            return result.mensaje 
            ? result.mensaje.includes('producto no encontrado') 
            : false
          })
        : []
    },
    textAction() {
      return this.toUpdate ? "Actualizar" : "Crear"
    },
    textImport() {
      return this.fileImport ? 'Actualización manual' : 'Importar'
    },
  },
  created() {
    this.loadInitialData()
  },
  methods: {
    ...mapActions(['setLoading']),
    async loadInitialData() {
      this.waitingSearch = true
      const documents = await this.fetchDocumentsByDistributor(this.distributorId)
      if (documents.documentos.length > 0) {
        const docs = documents.documentos
        const priceDocuments = docs.filter(d => d.nombre_documento.toLowerCase().includes('precio'))
        this.priceDocuments = priceDocuments ? priceDocuments : []
      }
      await this.getProductos()
      this.filterByName()
      this.waitingSearch = false
    },
    async fetchDocumentsByDistributor(distributorId) {
      try {
        const response = await ApiAuth.post('/personas/documento-distribuidor', {
          id_distribuidor: distributorId
        })
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener documentos de distribuidor', error);
      }
    },
    async getProductos() {
      const productResponse = await ApiAuth.get('/api/products/')
      const productResponseData = await productResponse.data
      this.allProducts = productResponseData.results
    },
    async getProduct(productId) {
      try {
        const response = await ApiAuth.get(`/api/products/${productId}/`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener un producto.', error);
      }
    },
    filterByName() {
      const searchLower = this.search.toLowerCase()
      const filter = this.allProducts.filter(product => {
        const productNameLower = product.nombre_producto.toLowerCase()
        if (productNameLower.includes(searchLower)) {
          return product
        }
      })
      this.filteredProducts = filter
    },
    edit(product) {
      this.editing = true
      this.product.id = product.id
      this.product.nombre_producto = product.nombre_producto
      const price = this.getPrice(product)
      this.product.precio_distribuidor = price.precio_distribuidor ? price.precio_distribuidor : 0.0
      this.product.precioId = price.id ? price.id : null
      this.toUpdate = price.id ? true : false
    },
    async createPrice(product) {
      try {
        //this.$store.commit('setLoading', true)
        const response = await ApiAuth.post('/crear-precio-producto',
        {
          precio: product.precio_distribuidor,
          id_distribuidor: this.userData.distribuidorId,
          id_producto: product.id
        })
        const responseData = await response.data
        this.isSearch 
          ? this.updatePriceInSearch(product.id, this.userData.distribuidorId, product.precio_distribuidor) 
          : this.updatePriceInAllProducts(product.id, this.userData.distribuidorId, product.precio_distribuidor);
        this.editing = false
      } catch (error) {
        console.error(error)  
      } finally {
        //this.$store.commit('setLoading', false)
      }
    },
    async updatePrice(product) {
      try {
        //this.$store.commit('setLoading', true)
        // console.log('updatePrice: ', product);
        const responseUpdate = 
          await ApiAuth.put(`/api/precio-producto-distribuidor/${product.precioId}/`, {
            id_distribuidor: this.userData.distribuidorId,
            precio_distribuidor: product.precio_distribuidor
          })
        const responseUpdateData = await responseUpdate.data
        // console.log('responseUpdateData:', responseUpdateData);
        if (responseUpdateData.error) {
          this.$store.commit('setAlert', {
            show: true,
            msg: 'No se pudo actualizar el precio',
            type: "error"
            })
        } else {
          if(this.isSearch) {
            this.updatePriceInSearch(product.id, this.userData.distribuidorId, product.precio_distribuidor)
          } else {
            this.updatePriceInAllProducts(product.id, this.userData.distribuidorId, product.precio_distribuidor)
          }
          this.editing = false
        }
      } catch (error) {
        console.error(error)
      } finally {
        //this.$store.commit('setLoading', false)
      }
    },
    async deletePrice(product) {
      try {
        this.$store.commit('setLoading', true)
        const price = this.getPrice(product)
        const priceId = price.id ? price.id : null
        if (priceId) {
          const response = await ApiAuth.delete(`/api/precio-producto-distribuidor/${priceId}/?id_distribuidor=${this.userData.distribuidorId}`)
        } 
      } catch (error) {
        console.error(error) 
      } finally {
        this.$store.commit('setLoading', false)
      }
    },
    actionButton() {
      if(!this.$refs.form.validate()) {
        return 
      }
      if (this.toUpdate) {
        this.updatePrice(this.product)
      } else {
        this.createPrice(this.product)
      }
    },
    cancel() {
      this.editing = false
      this.clear()
    },
    clear() {
      this.product = {
        nombre_producto: null,
        precio_distribuidor: null
      }
    },
    clearText() {
      this.search = '';
      this.waitingSearch = false
      this.isSearch = false
      //this.filterByName()
      this.filteredProducts = this.allProducts
    },
    async onPageChange(toPage, fromPage) {
      if (!this.isSearch) {
        if (toPage === this.calculatePaginateLimit()) {
          this.waitingSearch = true
          this.responsePage++
          await this.getPaginatedProducts(this.responsePage)
          this.waitingSearch = false
        }
      }
    },
    async searchText() {
      try {
        this.isSearch = true
        const cleanSearch = this.search.trim()
        if (cleanSearch.length === 0) {
          this.clearText()
        } else {
          this.waitingSearch = true
          if (cleanSearch.length > 2) {
            const searchResult = await this.searchProductByName(this.search, this.userData.sucursalId)
            // console.log('searchResult: ', searchResult);
            this.filteredProducts = searchResult
            this.waitingSearch = false
          }
        }
      } catch (error) {
        console.error('Error al buscar producto por nombre');
      }
    },
    async getPaginatedProducts(page) {
      const productResponse = await ApiAuth.get(`/api/products/?page=${page}`)
      const productResponseData = await productResponse.data

      if (productResponseData.detail) {
        // console.log("No se pudieron obtener los producto. ");
      } else {
        const tempProducts = productResponseData.results
        this.allProducts.push(...tempProducts)
        this.filteredProducts = this.allProducts
        //this.filterByName()
      }
    },
    calculatePaginateLimit() {
      return (this.allProducts.length % this.perPage) > 0 
        ? parseInt(this.allProducts.length / this.perPage) + 1
        : parseInt(this.allProducts.length / this.perPage)
    },
    getPrice(product) {
      const price = product.precios.find(pr => pr.distribuidor === this.userData.distribuidorId)
      if (price === null || price === undefined || price.length === 0) {
        return "Sin precio"
      }
      return price
    },
    async updatePriceInAllProducts(idProduct, idDistributor, price) {
      const productUpdated = await this.getProduct(idProduct)
      const productIndex = this.allProducts.findIndex(product => product.id === idProduct)
      this.allProducts[productIndex].precios = productUpdated.precios
      const productSelected = this.allProducts.find(product => product.id === idProduct)
      const stringPrice = price.toFixed(2)
      const priceToUpdate = {
        id: 'NoDefinido' + idProduct,
        precio_distribuidor: stringPrice,
        distribuidor: idDistributor,
        moneda: 1
      }
      const distributorPrice = productSelected.precios.find(pr => pr.distribuidor === idDistributor)
      
      if (distributorPrice) {
        distributorPrice.precio_distribuidor = stringPrice
      } else {
        productSelected.precios.push(priceToUpdate)
      }
    },
    async updatePriceInSearch(idProduct, idDistributor, price) {
      const productUpdated = await this.getProduct(idProduct)
      const productIndex = this.filteredProducts.findIndex(product => product.id === idProduct)
      this.filteredProducts[productIndex].precios = productUpdated.precios
      const productSelected = this.filteredProducts.find(product => product.id === idProduct)
      const stringPrice = price.toFixed(2)
      const priceToUpdate = {
        id: 'NoDefinido' + idProduct,
        precio_distribuidor: stringPrice,
        distribuidor: idDistributor,
        moneda: 1
      }
      const distributorPrice = productSelected.precios.find(pr => pr.distribuidor === idDistributor)
      
      if (distributorPrice) {
        distributorPrice.precio_distribuidor = stringPrice
      } else {
        productSelected.precios.push(priceToUpdate)
      }
    },
    async uploadFile() {
      try {
        this.importing = true
        const fileName = this.priceFile.name
        const formData = new FormData()
        formData.append('File', this.priceFile)
        const responseUpload = await ApiAuth.post('/subir-archivo-precios', 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          const responseUploadData = await responseUpload.data
          // console.log('responseUploadData: ', responseUploadData);
          if (responseUploadData.mensaje.includes('Archivo guardado con')) {
            // console.log('Si Dentro de responseUploadData');
            const fileToImport = responseUploadData.archivos[0][`${fileName}`]
            // console.log('fileToImport: ', fileToImport);
            const responseImport = await ApiAuth.post('/importar-precios',{
              url_archivo: fileToImport,
              id_distribuidor: this.userData.distribuidorId,
            })
            const responseImportData = await responseImport.data
            // console.log('responseImportData preciosActualizados: ', responseImportData['Precios Actualizados']);
            if (responseImportData.error) {
              this.crashImport.push('No se pudo procesar el archivo: ' + responseImportData.e.join(', '))
            } else if (responseImportData['Precios Actualizados'].length > 0) {
              this.importResults = responseImportData['Precios Actualizados']
            } else {
              this.emptyFile = true
            }
            
            // console.log('importResults: ', this.importResults);
          } else {
            //console.log('no entro al includes');
          }
        setTimeout(() => {
          this.importing = false
        }, 500)
        
      } catch (error) {
        
      }
    },
    clearInventoryVariables() {
      this.priceFile = null
      this.importResults = []
      this.emptyFile = false
      this.crashImport = []
    },
    downloadTemplate() {
      const documents = this.priceDocuments
      for(const doc of documents) {
        this.downloadDocument(doc)
      }
    },
    async downloadDocument(doc) {
      try {
        //console.log('Descargar documento', doc);
        const documentUrl = doc.documento;
        const fileName = doc.nombre_documento;

        const response = await axios({
          url: documentUrl,
          method: "GET",
          responseType: "blob",
        });
        const responseData = await response.data;
        const fileblob = new Blob([responseData]);
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(fileblob)
        link.download = `${fileName}`
        link.click()
      } catch (error) {
        console.error('Error al intentar descargar un documento', error);
      }
    },
  },
}
</script>

<style>
.pagination {
  display: flex;
  margin-top: 16px;
  text-align: center;
  justify-content: center;
  list-style-type: none;
}
.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.page-link {
  font-weight: bolder;
}
.active {
  background: #2D4761;
}
</style>